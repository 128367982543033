<template>
    <b-card title="List Transaction Log Record" sub-title="Log of everytime DO Status is changed">
      <b-modal v-model="deleteModal" title="Delete Transaction">
        <b-container fluid>
          <b-row class="mb-1">
            <b-col>
              <label for="moduleSelect">Select Module:</label>
              <b-form-select id="moduleSelect" v-model="selectedModule" :options="moduleOptions"></b-form-select>
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col>
              <label for="startDate">Start Date:</label>
              <b-form-datepicker id="startDate" v-model="startDate"></b-form-datepicker>
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col>
              <label for="endDate">End Date:</label>
              <b-form-datepicker id="endDate" v-model="endDate"></b-form-datepicker>
            </b-col>
          </b-row>
        </b-container>
        <template #modal-footer>
          <div class="w-100 d-flex justify-content-center align-items-center">
            <b-button 
              variant="success" 
              class="ml-1" 
              @click="deleteTransactions" 
              :disabled="tableLoading"
              >Confirm</b-button
            >
            <b-button
              variant="danger"
              class="ml-1"
              @click="deleteModal = false"
              >Cancel</b-button
            >
          </div>
        </template>
      </b-modal>
      <b-col cols="12">
        <b-row class="mt-2">
          <b-col md="4"> 
            <b-form-input
              v-model="search"
              type="search"
              placeholder="Search by Do Number"
              style="float: right;"
            />
          </b-col>
          <b-col cols="4" md="auto">
            <b-btn variant="primary" @click="searchByDO()" class="text-nowrap">
              Search
            </b-btn>
          </b-col>
        </b-row>
        <b-row class="justify-content-end flex-wrap">
          <b-col cols="12" md="auto" class="mb-1">
            <b-btn variant="danger" @click="remove()" class="text-nowrap">
              Remove Transaction Log
            </b-btn>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" class="table-responsive">
            <b-table
              :items="transaction"
              :fields="fields"
              :busy.sync="tableLoading"
              show-empty
            >
              <template #cell(skipScan)="row">
                {{row.item.skipScan||"false"}}
              </template>
            </b-table>
          </b-col>
  
          <b-col cols="12">
            <b-pagination
              :total-rows="totalRows"
              :per-page="perPage"
              @change="onPageChange"
              v-model="currentPage"
              style="float: right"
            />
          </b-col>

        </b-row>
      </b-col>
    </b-card>
  </template>
  
  <script>
  import { mapGetters, mapActions } from 'vuex';
  import axios from '@/axios';
  import {userAccess, dateFormat} from '@/utils/utils'
  
  export default {
    data() {
      return {
        downloadModal:false,
        fields: [
          { key: 'sapDo', label: 'sapDo', filterable: true, sortable: true },
          { key: 'user', label: 'User', filterable: true, sortable: true },
          { key: 'statusBefore', label:'Status Before' },
          { key: 'statusAfter', label: 'Status After' },
          { key: 'createdAt', label: 'Created At' },
        ],
        search: '',
        currentPage: 1,
        perPage: 10,
        totalRows: 1,
        tableLoading: false,
        deleteModal: false,
        selectedModule: null,
        startDate: null,
        endDate: null,
        transaction : [],
        moduleOptions: ["Edit DO Status", "Disapproved Return"],
      }
    },
    mounted() {
      
    },
    methods: {
      searchByDO(){
        this.currentPage=1;
        this.getTransaction({ 
          page: this.currentPage, 
          entry: this.perPage,
          sapDo: this.search
        })
      },
      onPageChange(page) {
        //let filter = this.getFilter();
        console.log("page", page);
        this.getTransaction({ 
          page: page, 
          entry: this.perPage,
        })
      },
      processMetadata(metadata) {
        let { totalData } = metadata;
        console.log('total data ',totalData)
        this.totalRows = totalData;
        this.tableLoading = false;
      },
      getTransaction(filter) {
        axios.get(`sales-and-purchase/drcc/transaction-log-record`, { params: { ...filter } }).then((response) => {
          const formattedData = response.data.data.map(item => {
          // Mendapatkan tanggal dan waktu dari item.createdAt
          const date = new Date(item.createdAt);

          // Mendapatkan komponen tanggal dan waktu
          const day = date.getDate();
          const month = date.getMonth() + 1; // Ingat bahwa bulan dimulai dari 0
          const year = date.getFullYear();
          const hours = date.getHours();
          const minutes = date.getMinutes();
          const seconds = date.getSeconds();

          // Format tanggal dan waktu sesuai kebutuhan
          const formattedDate = `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}, ${hours.toString().padStart(2, '0')}.${minutes.toString().padStart(2, '0')}`;

          // Mengembalikan objek dengan createdAt yang diformat
          return {
              ...item,
              createdAt: formattedDate
          };
      });
          this.processMetadata(response.data.metadata)
          this.transaction=formattedData
          return response.data;
        }).catch((e) => {
          console.log(e);
        })
      },
      remove(){
        this.deleteModal = true
      },
      deleteTransactions(){
        if(!this.selectedModule){
          this.$bvToast.toast("Please select module first", {
            title: "Failed",
            variant: "danger",
            solid: true,
          });
          return ;
        } 
        if(!this.startDate){
          this.$bvToast.toast("Please add start date", {
            title: "Failed",
            variant: "danger",
            solid: true,
          });
          return ;
        }
        if(!this.endDate){
          this.$bvToast.toast("Please add end date", {
            title: "Failed",
            variant: "danger",
            solid: true,
          });
          return ;
        }
        axios.post(`sales-and-purchase/drcc/remove-transaction-log-record`, { startDate : this.startDate, endDate : this.endDate, selectedModule : this.selectedModule }).then((response) => {
          this.$bvToast.toast("Delete Transaction Success", {
            title: "Success",
            variant: "success",
            solid: true,
          });
          this.deleteModal = false
          this.getTransaction({ 
            page: page, 
            entry: this.perPage,
          })
        }).catch((e) => {
          console.log(e);
          this.$bvToast.toast(e.response.data.message, {
            title: "Error",
            variant: "danger",
            solid: true,
          });
        })
      },
    },
    computed: {
      permission() {
        return userAccess("Transaction Log Record", "utilitytools_menu");
      },
    },
    created() {
      document.title = 'Transaction Log Record | RSP';
      this.getTransaction({
        page: this.currentPage, 
        entry: this.perPage,
      })
    },
  }
  </script>
  <style scoped>
  @media (min-width: 1423px) {
    .hide-on-mobile { display: none !important; }
    .show-on-desktop {display: inline !important;}
  }
  
  @media (max-width: 1422px) {
    .hide-on-desktop { display: none !important; }
  }
  </style>